/** Sidebar */

.main-sidebar {
    --sidebar-padding: 12px;
    background: #FFF;

    .brand-link {
        padding: 20px var(--sidebar-padding) 25px;
    }

    .sidebar {
        padding: 0 var(--sidebar-padding);

        .nav-link {
            width: calc(250px - var(--sidebar-padding) * 2);
        }

        .nav.ml-2 .nav-link { /** next level */
            width: calc(250px - (var(--sidebar-padding) * 2) - 0.5rem); // like a .ml-2 space
        }

        .nav.ml-2 .nav.ml-2 .nav-link {
            width: calc(250px - (var(--sidebar-padding) * 2) - (2 * 0.5rem)); // like a 2 * .ml-2 space
        }
    }

    .nav-sidebar {
        .nav-item .nav-icon,
        .nav-subitem .nav-icon {
            position: relative;
            top: -1px;
            margin-left: 0;
            margin-right: 0.2rem;
            font-size: 12px;
            text-align: left;
            width: auto;
        }

        .nav-treeview > .nav-item > .nav-link > .nav-icon {
            width: auto;
        }
    }


    /** Sidebar nav links */

    .nav-subitem {
        position: relative;
    }

    .nav-link {
        padding: 6.5px 1rem;
        padding-left: 0;
        // font-family: var(--font-family-base);
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        color: var(--black-1);
    }


    .nav-link:not(.active):hover,
    .nav-link:not(.active):focus {
        color: var(--black-1);
        opacity: 0.8;
    }

    /** active */
    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        background-color: transparent;
        color: var(--green);
        font-weight: 700;
    }

    .nav-pills .nav-link.active:hover,
    .nav-pills .show>.nav-link:hover {
        color: var(--green);
        opacity: 0.8;
    }

    .fa-angle-left {
        color: var(--violet);
        font-size: 12px;
        line-height: 14px;
    }

    .nav-sidebar .nav-link > .fa-angle-left,
    .nav-sidebar .nav-link > p > .fa-angle-left {
        right: 16px;
    }

    .badge {
        font-weight: 700;
    }

    .nav-sidebar .nav-link>.right:nth-child(2),
    .nav-sidebar .nav-link>p>.right:nth-child(2) {
        right: 1.8rem;
    }
}
