.title-bar {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	padding-top: 0.25rem;
	margin-bottom: 10px;

	&__title {
		margin-right: 15px;
		margin-bottom: 15px;
	}

	&__actions {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: auto;
		margin-bottom: 15px;
	}
}
