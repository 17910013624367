.col-main {
    position: relative;
    padding-right: 7.5px;
    padding-left: 7.5px;
    flex: 0 0 auto;
    width: 100%;

    @include from(media(lxl)) {
        width: 83.33333333%; // 10/12
    }
}
