.custom-checkbox--list {
    input {
        display: none;
    }

        // States
    &_justify {
        .custom-checkbox {
            justify-content: space-between;
        }
    }
}

.custom-checkbox {
    --color-disabled: #CCCECF;
    --color-enabled: var(--green);

    display: flex !important;
    align-items: center;
    margin: 2px 10px 2px 0;
    cursor: pointer;

    &__label {
        margin-right: 5px;
        font-weight: normal;
        font-size: 12px;
        color: var(--black-1);
    }

    &__state {
        position: relative;
        display: inline-block;
        width: 28px;
        height: 12px;
        border: 1px solid #CCCECF;
        border-radius: 2px;
    }

    &__state::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 8px;
        top: 50%;
        left: 1px;
        background-color: var(--color-disabled);
        border-radius: 2px;
        transform: translate(0%, -50%);
        transition: all .2s ease;
    }

    input:checked ~ &__state::after {
        transform: translate(100%, -50%);
        background-color: var(--color-enabled);
    }
}
