.review__comments--wrap {
    display: flex;

    .review__comments {
        flex: 1;
        padding-right: 5px;

        [data-tone] {
            cursor: pointer;
        }

        [data-tone=pos].topic {
            background: rgb(117, 200, 174);
        }
        [data-tone=neg].topic {
            background: #dc3545;
            color: #ffffff;
        }
        [data-tone=neut].topic {
            background: #6c757d;
            color: #ffffff;
        }
    }

    .review__topics {
        width: 25%;
        flex: 0 0 auto;
        padding-left: 5px;

        ul {
            list-style: none;
            padding-left: 0;
        }

        li {
            cursor: pointer;
        }

        li::before {
            content: '- ';
        }

        li:not(.active):hover {
            background: rgba(117, 200, 174, 0.5);
        }

        li:not(.active):hover a {
            color: #45ac8c;
        }

        .active {
            background: rgb(117, 200, 174);
            font-weight: bold;
        }

        .active a {
            color: rgb(65, 64, 66);
        }
    }
}

.review {
    --column-1: 250px;
    --column-2: 150px;
    --column-3: 250px;
    --column-4: 250px;

    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: var(--black-1);

    [data-kw-mark] {
        background: yellow;
    }

    label {
        display: block;
        margin-bottom: 4px;
    }

    &__product-name {
        width: 100%;
        margin: 0 0 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: var(--black-1);
    }

    &__body {
        margin-bottom: 8px;
        display: grid;
        grid-template-columns: max-content var(--column-2) minmax(var(--column-2), var(--column-1)) minmax(var(--column-2), 1fr);
        width: 100%;
        max-width: unset;
        row-gap: 8px;
        column-gap: 10px;
    }

    @media (max-width: 1449px) {
        &__body {
            grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        }
    }

    &__comments {
        width: 100%;
    }

    &__comment {
        display: inline-block;
    }

    &__review-star {
        white-space: nowrap;

        i {
            color: var(--primary);
        }
    }

    &__review-pos {
        width: 100%;
        margin-bottom: 5px;
    }

    &__review-neg {
        width: 100%;
        margin-bottom: 5px;
    }

    &__review-text {
        width: 100%;
    }

    &__comment {
        overflow: auto;
        max-height: 40px;
        margin-bottom: 5px;
        transition: .2s linear;
    }

    &__more {
        display: none;
    }

    .has-scroll {
        .review__comment {
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 10px;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
                pointer-events: none;
            }
        }

        .review__more {
            display: block;
            order: 99;

            input {
                display: none;
            }
        }

        .toggle-down {
            display: inline-block;
        }

        .toggle-up {
            display: none;
        }

        &.active {
            .review__comment {
                max-height: 1000px;

                &::after {
                    opacity: 0;
                }
            }

            .toggle-down {
                display: none;
            }

            .toggle-up {
                display: inline-block;
            }
        }

        .toggle-up, .toggle-down {
            text-decoration: underline;
        }
    }
}

.topics__reaction {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1;
}
.markup {
    max-height: 360px;
    padding:5px;
    overflow-y: scroll;
}

.markup__topics {
    .tone-pos .topics__pos {font-weight: bold; color: inherit}
    .tone-neg .topics__neg {font-weight: bold; color: inherit}
    .tone-neut .topics__neut {font-weight: bold; color: inherit}
}

.topics {
    input[id*=topic-name] {
        border: 1px solid transparent;
        background: transparent;
        border-radius: 0.25rem;
    }

    .topics__edited input[id*=topic-name] {
        border: 1px solid #ced4da;
    }

    .topics__btn-icon {
        height: 25px;
        width: 25px;
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
    }

    .topics__edited .topics__edit-on,
    .topics__edit-update,
    .topics__edit-cancel {
        display: none;
    }

    .topics__edited .topics__edit-update,
    .topics__edited .topics__edit-cancel {
        display: initial;
    }
}

