.ui-datepicker {
    background: #FFF;
    padding: 8px 16px;

    .ui-datepicker-header {
        display: flex;
        width: 100%;
        height: 40px;
        justify-content: space-between;
        align-items: center;

        .ui-datepicker-prev {
            order: -1;
            flex: 1;
            text-align: left;
            cursor: pointer;
        }

        .ui-datepicker-title {
            order: 10;
            text-align: center;
        }

        .ui-datepicker-next {
            order: 15;
            flex: 1;
            text-align: right;
            cursor: pointer;
        }
    }

    table.ui-datepicker-calendar {
        th, td {
            width: 30px;
            height: 30px;
            text-align: center;
        }
    }
}
