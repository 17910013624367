.menu {
  display: inline-block;
  background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #EBECF0;
  background-blend-mode: soft-light, normal;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px #FAFBFF, inset 5px 5px 10px #A6ABBD;
  border-radius: 40px;
  width: auto;
  height: 32px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.22px;

  .menu-item {
    display: inline-block;
    padding: 2px 20px 0;
    height: 31px;
    color: #5A3171;
  }

  .active {
    background: #421D57;
    border-radius: 40px;
  }

  a {
    text-decoration: none;
    color: #5A3171;
    vertical-align: middle;

    &:hover, &:focus {
      color: #5A3171;
    }
  }

  .active a {
    color: #EEECF1;
  }

  @include to(media(hd)) {
    font-size: 13px;

    .menu-item {
      padding: 2px 10px 0;
    }
  }
}
