.report {
    .Select {
        &.is-focused:not(.is-open) > .Select-control {
            border-color: #d9d9d9 #ccc #b3b3b3;
            box-shadow: none;
        }
    }

    .Select--single {
        .Select-value {
            font-size: 12px;
        }
    }

    .Select--multi {
        .Select-value-icon {
            border-right: 0;
            font-size: 16px;
            line-height: 10px;
            padding-right: 1.5px;

            &:hover {
                opacity: .85;
            }
        }

        .Select-placeholder {
            font-size: 12px;
        }

        .Select-value-label {
            padding-left: 2px;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
        }
    }
}
