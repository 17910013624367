.btn.toggle {
    label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
    }

    .toggle-on {
        padding-right: 1.5rem;
    }
}
