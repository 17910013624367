.datatable--wrapper {
    .table-responsive .dataTables_filter {
        text-align: right;
    }

    .table-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .table-buttons {
            margin-bottom: 0.5rem;

            .btn {
                margin-bottom: 0.25rem;
            }
        }

        .dataTables_filter {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 5px;
            margin-bottom: 12px;
            color: var(--secondary);

            label {
                margin: 0;
                font-size: 0;
                color: transparent;
            }

            input {
                width: 240px;
                margin-left: 0;
            }
        }
    }

    table.dataTable {
        > thead {
            background: $table-head-bg;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #414042;

            th {
                padding: 11px 0.75rem;
                border-bottom: 0;
            }

            tr .sorting,
            tr .sorting_asc,
            tr .sorting_desc,
            tr .sorting_asc_disabled,
            tr .sorting_desc_disabled {
                &::before, &::after {
                    display: none;
                }
            }

            .sorting {
                .sort {
                    --sort-color: #b4b4b4;

                    &::before, &::after {
                        content: '';
                        position: absolute;
                        left: auto;
                        right: auto;
                        display: inline-block;
                        margin-left: 4px;
                        border: 4px solid transparent;
                    }

                    &::before {
                        top: calc(50% - 10px);
                        bottom: auto;
                        border-bottom: 4px solid var(--sort-color);
                    }

                    &::after {
                        top: 50%;
                        bottom: auto;
                        border-top: 4px solid var(--sort-color);
                    }
                }

                &:hover .sort {
                    --sort-color: var(--secondary);
                }

                &_desc {
                    .sort::before {
                        opacity: 0;
                    }
                }

                &_asc {
                    .sort::after {
                        opacity: 0;
                    }
                }
            }

            .datatable-column-filter-row {
                input, select {
                    display: block;
                    width: 100%;
                    font-weight: 300;
                    color: #414042;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    height: calc(1.6484375rem + 2px);
                    padding: 0.25rem 0.5rem;
                    font-size: 0.765625rem;
                    line-height: 1.5;
                    border-radius: 0.2rem;

                    &:focus {
                        outline: none;
                    }
                }

                th {
                    border: 0;
                    padding-top: 0;
                }
            }
        }

        > tbody {
            th, td {
                border-top: 0;
                line-height: 16px;
            }
        }

        &.table-striped {
            > tbody {
                tr {
                    background: #FFF;
                }

                tr:nth-of-type(even) {
                    background: $table-striped-bg;
                }
            }
        }
    }

    @include from(media(md)) {
        flex-direction: row;
    }
}

.table-footer {
    --info-width: 25%;
    --pagination-width: 50%;
    --pagesize-width: 25%;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    .dataTables_info,
    &__info {
        flex: 0 0 auto;
        width: var(--info-width);
    }

    .dataTables_paginate,
    &__pagination {
        order: -1;
        flex: 0 0 auto;
        width: var(--pagination-width);

        .pagination {
            margin: 1rem auto;
            justify-content: center;
        }
    }

    .dataTables_length,
    &__page-size {
        flex: 0 0 auto;
        width: var(--pagesize-width);
        white-space: nowrap;

        > label {
            display: block;
            width: 100%;
            font-weight: 300;
            margin: 1rem 0 1rem auto;
        }

        select {
            width: 60px;
        }
    }

    @include from(media(md)) {
        .dataTables_info,
        &__info {
            order: 10;
            padding: 1rem 0;
        }

        .dataTables_paginate,
        &__pagination {
            order: 20;
        }

        .dataTables_length,
        &__page-size {
            order: 30;
            text-align: right;
        }
    }

    @include to(media(md)) {
        flex-direction: column;

        --info-width: 100%;
        --pagination-width: 100%;
        --pagesize-width: 100%;
    }
}

.col-9 > .card .table-footer {
    @include to(media(lg)) {
        --info-width: 100%;
        --pagination-width: 100%;
        --pagesize-width: 100%;

        flex-direction: column;
    }
}
