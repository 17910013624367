@import "report/review";
@import "report/table";
@import "report/select";

.report {
    &__filter {
        @include to(media(md)) {
            order: -1;
        }
    }

    .card {
        background-color: #fff;
    }

    .form-group-label {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #414042;
    }

    .DateRangePickerInput__withBorder {
        border-radius: 4px;
    }

    .DateInput {
        width: 85px;

        &_input {
            padding: 3px 10px;
            font-size: 14px;
        }
    }

    .card {
        &_header {
            z-index: 500;
            position: relative;

            .card-body {
                display: flex;
            }

            .form-group {
                margin: 0 1rem 0 0;
            }

            .Select-control {
                min-width: 200px;
            }

            .period-filter-select {
                display: inline-block;
                padding: 8px;
            }

            @include to(media(md)) {
                .form-group {
                    margin: 0 0 1rem;
                }
            }

            @include to(media(xl)) {
                .filter-group {
                    display: flex;
                    flex-direction: column;

                    .form-group {
                        margin-bottom: 0.5rem;
                    }
                }
            }

            @include to(media(md)) {
                .card-body {
                    flex-direction: column;
                }

                .filter-group {
                    margin: 0;

                    .form-group {
                        margin: 0 0 0.5rem;
                    }
                }
            }
        }
    }


    .card_filter {
        --slider-color: var(--primary);

        .rc-slider-track {
            background-color: var(--slider-color);
        }

        .rc-slider-dot-active, .rc-slider-handle {
            border-color: var(--slider-color);
        }

        .rc-slider-handle-dragging {
            box-shadow: 0 0 0 5px var(--slider-color);
        }

        .dash-dropdown {
            i, .fa {
                color: var(--primary);
            }
        }
    }

    .filter-group {
        display: flex;
        margin-right: 15px;

        @media (min-width: 1400px) {
            margin-right: 50px;
        }
    }

    .dash-rating {
        display: flex;
        align-items: center;
        width: 360px;
        max-width: 100%;
        margin-bottom: .5rem;

        .dash-dropdown {
            flex: 1;
            margin-left: 8px;
        }
    }

    .dash-graph--wrap {
        max-width: 100%;
        overflow: auto;
        margin-bottom: 1rem;
    }

    .df-empty {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .show-filters-mobile {
        display: none;
    }

    .dash-graph {
        min-width: 500px;
    }

    @include to(media(md)) {
        .show-filters-mobile {
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            &:not(.open)::after {
                content: '';
                position: relative;
                height: 0;
                width: 0;
                top: 2px;
                left: 8px;
                border-color: #999 transparent transparent;
                border-style: solid;
                border-width: 5px 5px 2.5px;

            }
        }

        .filters-mobile:not(.open) {
            display: none;
        }
    }
}

.report .rep-regions-page .dash-table {
    .dt-table-container__row {
        --scrollbar-foreground: #007eff; // #ebf5ff;
        --scrollbar-background: var(--black-1);
        --scrollbar-size: 5px;

        scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
    }

    .dt-table-container__row::-webkit-scrollbar {
        width: var(--scrollbar-size);
        height: var(--scrollbar-size);
    }

    .dt-table-container__row::-webkit-scrollbar-thumb {
        background: var(--scrollbar-foreground);
    }

    .dt-table-container__row::-webkit-scrollbar-track {
        background: var(--scrollbar-background);
    }

    .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner,
    .dt-table-container__row {
        th, td {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        // Headers
        th > div {
            justify-content: center;
        }

        // Body
        tr {
            height: 24px;
            min-height: 24px;
        }

        td {
            position: relative;
            font-weight: 500;

            &::before, &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 5px;
                display: block;
                background: #FFF;
            }

            &::after {
                top: auto;
                bottom: 0;
            }
        }

        tr:nth-child(even) td {
            &::before, &::after {
                background: var(--even-row-bg);
            }
        }
    }
}

#tooltip {
    z-index: 9999;
    display: inline-block;
    padding: 5px 10px;
    background: #ffffff;
    color: #000;
    font-weight: bold;
    font-size: 13px;
    border-radius: 4px;
    box-shadow: 2px 2px 10px #AAA;
}

[data-popper-arrow],
[data-popper-arrow]::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

[data-popper-arrow] {
    visibility: hidden;
}

[data-popper-arrow]::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > [data-popper-arrow] {
    bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > [data-popper-arrow] {
    top: -4px;
}

#tooltip[data-popper-placement^='left'] > [data-popper-arrow] {
    right: -4px;
}

#tooltip[data-popper-placement^='right'] > [data-popper-arrow] {
    left: -4px;
}

.selected-bound {
    position: absolute;
    pointer-events: none;
}

.markup {
    &__header {
        margin-bottom: 8px;
    }

    &__text {
        color: #643045;
    }
}