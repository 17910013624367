.form-list_radios .form-list-group {
  display: flex;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #414042;
  letter-spacing: 0;
  text-align: left;
}

.form-list_radios .form-list-input {
  display: none;
}

.form-list_radios .form-list-label {
  position: relative;
  margin-bottom: 2px;
  padding-left: 14px;
  cursor: pointer;
}

.form-list_radios .form-list-input ~ .form-list-label::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.form-list_radios .form-list-input:checked ~ .form-list-label::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 2px;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #30B58E;
}

.form-list_radios.form-list_inline {
  display: flex;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
}

.form-list_radios.form-list_xl .form-list-label {
  margin-top: 2px;
  padding-left: 22px;
}

.form-list_radios.form-list_xl .form-list-input ~ .form-list-label::before {
  top: 0px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid;
}

.form-list_radios.form-list_xl .form-list-input:checked ~ .form-list-label::after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
}

.grid-region .form-list_radios .form-list-group {
  padding-top: 2px;
  padding-bottom: 2px;
  align-items: center;
}

.simplebar-content .form-list_radios .form-list-group:hover,
.simplebar-content .form-list_radios .active {
  background-color: #30B68F;
}

.simplebar-content .form-list_radios .form-list-group:hover .form-list-input:checked ~ .form-list-label::after,
.simplebar-content .form-list_radios .active .form-list-input:checked ~ .form-list-label::after {
  background: #414042;
}

.simplebar-content .form-list_radios .form-list-group:hover .js-toggle-list,
.simplebar-content .form-list_radios .active .js-toggle-list {
  filter: grayscale(1) brightness(0.5);
}

.simplebar-content .form-list_radios .form-list-label {
  margin-top: 3px;
}

.simplebar-content .form-list_radios .form-list-group .js-toggle-list {
  margin-top: 3px;
  margin-bottom: 3px;
}