.custom-radio--list {
    input {
        display: none;
    }

    // States
    &_justify {
        .custom-radio {
            justify-content: space-between;
        }
    }

    &_inline {
        display: flex;
    }
}

.custom-radio {
    --color-disabled: #CCCCCC;
    --color-enabled: var(--green);

    display: flex !important;
    align-items: center;
    margin: 2px 10px 2px 0;
    cursor: pointer;

    &__label {
        font-weight: normal;
        font-size: 12px;
        color: var(--black-1);
    }

    &__state {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border: 1px solid var(--color-disabled);
        border-radius: 100%;
    }

    &__state::after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-enabled);
        border: 2px solid #FFF;
        border-radius: 100%;
        box-shadow: 0 0 0 1px var(--color-disabled);
        transition: opacity .2s ease;
        opacity: 0;
    }

    input:checked ~ &__state::after {
        opacity: 1;
    }
}
