.content-header .breadcrumb {
    margin-bottom: 15px;
}

.content-header .breadcrumb-item, .content-header .breadcrumb-item a {
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    color: $breadcrumb-link-color;
}

.content-header .breadcrumb-item a {
    text-decoration: underline;
}
