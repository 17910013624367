.placeholder {
    --height: 32px;
    --padding: 8px 15px;
    --border-radius: 20px;
    --icons-offset: 35px;

    position: relative;
    padding: var(--padding);
    background: linear-gradient(317.7deg, rgba(0, 0, 0, .4) 0, rgba(255, 255, 255, .4) 105.18%), #ebecf0;
    background-blend-mode: soft-light, normal;
    box-shadow: inset -5px -5px 10px #fafbff, inset 5px 5px 10px #a6abbd;
    border: 1px solid rgba(255, 255, 255, .4);
    border-radius: 20px;

    &_input {
        padding: 0;
        height: var(--height);

        > input {
            width: 100%;
            height: var(--height);
            outline: none;
            border: 0;
            background: transparent;
            padding: var(--padding);
            font-size: 14px;
            border-radius: var(--border-radius);

            &:focus {
                background-color: #FFF;
            }
        }
    }

    &_inline {
        line-height: 14px;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_icon {
        padding-right: var(--icons-offset);

        &.placeholder_input {
            padding: 0;

            > input {
                padding-right: var(--icons-offset);
            }
        }

        .icon {
            position: absolute;
            right: 15px;
            top: calc(50% - (var(--size) / 2));
        }
    }
}
