.report {
    .dash-table {
        --head-bg: #{$table-head-bg};
        --even-row-bg: #{$table-striped-bg};
        color: #212529;
        margin-bottom: 1rem;

        .dash-spreadsheet-container {
            font-size: 12px;
            line-height: 15px;
        }

        .cell-table,
        .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner table {
            --accent: var(--secondary);
            font-family: var(--font-family-base);
        }

        .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner td,
        .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner th {
            text-align: left;
            padding: 0.75rem;
        }

        .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner th,
        .dt-table-container__row-0 th {
            background: var(--head-bg);
            color: $table-head-color;
            font-weight: $table-th-font-weight;
            padding: 10px 0.75rem;
        }

        .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner {
            tr:nth-child(even), tr:nth-child(even) td {
                background-color: var(--even-row-bg);
            }
        }

        .dt-table-container__row-0 th > div {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            .column-actions {
                margin-left: 4px;
            }
        }

        .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner .column-header-name {
            flex-grow: 0;
        }

        .dash-cell {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 0;
            font-stretch: ultra-condensed;
            border: 1px;
            border-style: initial;
            border-color: initial;
        }
    }

    .table-footer__pagination {
        --font-size: 14px;

        .page-item, .page-link {
            font-size: var(--font-size);
        }

        .page-link {
            > span {
                display: none;
            }
        }

        @include to(media(xl)) {
            .page-item_arrow {
                .page-link {
                    font-size: 0;

                    > span {
                        display: inline;
                        font-size: var(--font-size);
                    }
                }
            }
        }

        @include to(media(md)) {
            --font-size: 12px;

            .page-link {
                padding: 0.5rem 0.625rem;
            }
        }
    }

    .table-footer__page-size {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .dash-dropdown {
            text-align: left;
            width: 60px;
            margin-left: 10px;
        }
    }

    @include to(media(lxl)) {
        .table-footer {
            --info-width: 50%;
            --pagination-width: 100%;
            --pagesize-width: 50%;

            flex-wrap: wrap;

            &__pagination {
                order: -1;
            }

            &__page-size {
                margin-left: auto;
            }
        }
    }
}
