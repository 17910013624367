:root {
  --font-family-base: #{$base-font-family};

  --violet: #{$secondary};
  --green: #{$primary};
  --black-1: #{$gray-700};
}

.table-link {
  display: inline-block;
  max-width: 400px;
  word-wrap: break-word;
}

// Bootstrap
.dropdown-toggle::after {
  display: none;
}

.form-control {
  &::placeholder {
    color: #CCCCCC;
  }
}

.btn {
  padding: 4px 0.75rem;
}

.alert-success {
  color: #fff;
}

.btn-primary,
.btn-success {
  color: #FFF;

  &:hover {
    color: #FFF;
    opacity: 0.8;
  }
}

.badge-primary,
.badge-success,
.card-primary:not(.card-outline) > .card-header,
.card-primary:not(.card-outline) > .card-header > .card-title {
  &, a {
    color: #FFF;
  }
}

.badge {
  font-weight: 400;
}

.nav-pills .nav-link {
  font-weight: 400;
}

.nav-pills-secondary {
  .nav-link.active, .show > .nav-link {
    background-color: var(--secondary);
  }

  .nav-link:not(.active):hover {
    color: var(--secondary);
  }
}

// AdminLTE
.brand-link .brand-image {
  margin-left: 0;
}

.progress-sm {
  height: 6px;
}

.progress-bar {
  border-radius: $progress-bar-border-radius;
}

.content-header {
  padding: 10px 0.5rem;

  h1 {
    font-family: var(--font-family-base);
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: var(--secondary);
  }
}

// Base Custom
h1, h2, h3, h4 {
  color: var(--secondary);
}

.table thead th {
  padding: 5px 0.75rem;
  font-weight: 700;
}

.inline-buttons {
  display: flex;
  flex-wrap: wrap;

  .btn, .btn-sm {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

.opposite-title {
  display: flex;
  justify-content: flex-end;
  margin-top: -2rem;
  margin-bottom: 1rem;
}

#datatable_regions_attached_wrapper {
  .table-striped {
    min-width: 600px;
  }
}

.label-info {
  background-color: #75C8AE;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.tooltip__widget {
  width: 100%;
  position: relative;

  i:hover {

    & + .tooltip__widget__text {
      display: flex;
    }
  }
}

.tooltip__widget {
  &.bottom {
    .tooltip__widget__text {
      top: 115%;

      &::before {
        top: -5px;
        transform: rotate(180deg);
      }
    }
  }

  &.top {
    .tooltip__widget__text {
      bottom: 115%;

      &::before {
        bottom: -5px;
      }
    }
  }

  &.left {
    .tooltip__widget__text {
      transform: translateX(calc(-100% + 14px));

      &::before {
        right: 1px;
      }
    }

  }

  &.right {
    .tooltip__widget__text {
      &::before {
        left: 1px;
      }
    }
  }
}

.tooltip__widget__text {
  width: max-content;
  max-width: 250px;
  position: absolute;
  left: 0;
  display: none;
  font-weight: lighter;
  z-index: 3000;

  &::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }

  .tooltip-inner {
    width: 100%;
    max-width: unset;
    white-space: normal;
  }
}

.pointer {
  cursor: pointer;
}

.select_ch {
  white-space: nowrap;
  margin-top: 15px !important;
  font-size: 11px !important;
  padding: 3px 7px;
}

.btn-link {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.notify_date {
  font-size: 0.75em;
}

.table-content {
  position: relative;
  min-height: 150px;
}

.table--loading {
  opacity: 0.5;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.loader {
  position: relative;
  top: 87px;
  color: var(--green);
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

strong {
  font-weight: 600;
}
