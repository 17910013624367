.spoiler__button {
  position: relative;
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;
  }
}

.spoiler__body {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  display: flex !important;
  overflow: hidden;

  &.show {
    max-height: 550px;
    transition: max-height 0.25s ease-in;
    overflow: unset;
    overflow-y: scroll;
  }
}