$gray-500: #A7A9AC;
$gray-700: #414042;
$green: #75C8AE;

$primary: $green;
$secondary: #5A3A70;

$base-font-family: 'Inter', sans-serif;
$base-font-size: 0.875rem;
$base-font-weight: 300;

// Bootstrap
$headings-font-weight: 600;

$font-family-sans-serif: $base-font-family;
$font-size-base: $base-font-size;
$font-weight-base: $base-font-weight;

$progress-bg: $gray-700;
$breadcrumb-divider: quote("|");
$breadcrumb-link-color: $gray-500;

$table-head-bg: rgba(229, 236, 246, 1);
$table-head-color: $gray-700;
$table-th-font-weight: 700;

$table-striped-bg: #ecf1f8;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  lxl: 1600px,
);

// Adminlte
$progress-bar-border-radius: 3px;
