.push-messages {
    .dropdown-menu {
        --scrollbar-foreground: var(--green);
        --scrollbar-background: #CCC;
        --scrollbar-size: 5px;

        scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
    }

    .dropdown-menu::-webkit-scrollbar {
        width: var(--scrollbar-size);
        height: var(--scrollbar-size);
    }

    .dropdown-menu::-webkit-scrollbar-thumb {
        background: var(--scrollbar-foreground);
    }

    .dropdown-menu::-webkit-scrollbar-track {
        background: var(--scrollbar-background);
    }

    .dropdown-menu {
        list-style: none;
        max-height: 500px;
        overflow: auto;
    }
}

.push-message {
    &__title {
        font-size: 0.875rem;
    }

    &__body {
        overflow: hidden;
        white-space: normal;
        font-size: 0.75rem;
        line-height: 1.25;
        max-height: 5.625em; // {#max-height: calc((1.25 * 4em) + (1.25 / 2));#}
    }

    &_open &__body {
        overflow: auto;
        max-height: 30em;
    }

    &[data-overflown] {
        cursor: pointer;
    }

    .more {
        text-decoration: underline;
    }

    &:hover .more {
        text-decoration: none;
    }
}

.nav-link_notify .navbar-badge {
    padding: 1px 2px;
    font-size: 0.6rem;
    font-weight: 700;
}

.notify-list {
    padding-top: 10px;
    padding-left: 0;
}

.dropdown-menu__summary {
    position: sticky;
    bottom: 0;
    display: flex;

    a {
        width: 100%;
        padding: 8px;
        text-align: center;
        background-color: var(--green);
        color: #FFF !important;
    }

    a:hover {
        opacity: 0.95;
    }
}
