/** Navbar */
.main-header {
}

.main-header .badge {
    padding: 0 2px;
    font-size: 8px;
}

.main-header .dropdown__item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.main-header .dropdown-toggle .right {
    position: relative;
    top: 1px;
    font-size: 13px;
    color: var(--secondary);
}

.main-header .navbar-nav:first-child {
    font-weight: 300;
    font-size: 14px;
    color: #75C8AE;
}

.main-header .navbar-nav:last-child {
    i[class^="fa"] {
        color: var(--black-1);
    }

    i[class^="fa"]:hover {
        opacity: 0.8;
    }
}

.user-panel {
    @include to(media(sm)) {
        .fa-user {
            color: var(--black-1);
            margin-right: 3px;
            line-height: 30px;
        }

        .info {
            display: none;
        }

        + .dropdown-menu > span {
            color: var(--green);
        }
    }

    @include from(media(sm)) {
        .fa-user {
            display: none;
        }

        + .dropdown-menu > span {
            display: none;
        }
    }
}

.nav-item {
    &_separator {
        position: relative;

        &::before {
            content: '';
            display: block;
            border-top: 1px solid #CCC;
            margin-top: 2px;
            margin-bottom: 5px;
        }
    }
}
