:root {
  --font-family-base: Inter, sans-serif;
  --violet: #5A3A70;
  --green: #75C8AE;
  --black-1: #414042;
}

.table-link {
  display: inline-block;
  max-width: 400px;
  word-wrap: break-word;
}

.dropdown-toggle::after {
  display: none;
}

.form-control::placeholder {
  color: #CCCCCC;
}

.btn {
  padding: 4px 0.75rem;
}

.alert-success {
  color: #fff;
}

.btn-primary,
.btn-success {
  height: fit-content;
  color: #FFF;
}
.btn-primary:hover,
.btn-success:hover {
  color: #FFF;
  opacity: 0.8;
}

.badge-primary, .badge-primary a,
.badge-success,
.badge-success a,
.card-primary:not(.card-outline) > .card-header,
.card-primary:not(.card-outline) > .card-header a,
.card-primary:not(.card-outline) > .card-header > .card-title,
.card-primary:not(.card-outline) > .card-header > .card-title a {
  color: #FFF;
}

.badge {
  font-weight: 400;
}

.nav-pills .nav-link {
  font-weight: 400;
}

.nav-pills-secondary .nav-link.active, .nav-pills-secondary .show > .nav-link {
  background-color: var(--secondary);
}
.nav-pills-secondary .nav-link:not(.active):hover {
  color: var(--secondary);
}

.brand-link .brand-image {
  margin-left: 0;
}

.progress-sm {
  height: 6px;
}

.progress-bar {
  border-radius: 3px;
}

.content-header {
  padding: 10px 0.5rem;
}
.content-header h1 {
  font-family: var(--font-family-base);
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: var(--secondary);
}

h1, h2, h3, h4 {
  color: var(--secondary);
}

.table thead th {
  padding: 5px 0.75rem;
  font-weight: 700;
}

.inline-buttons {
  display: flex;
  flex-wrap: wrap;
}
.inline-buttons .btn, .inline-buttons .btn-sm {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.opposite-title {
  display: flex;
  justify-content: flex-end;
  margin-top: -2rem;
  margin-bottom: 1rem;
}

#datatable_regions_attached_wrapper .table-striped {
  min-width: 600px;
}

.label-info {
  background-color: #75C8AE;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.tooltip__widget {
  width: 100%;
  position: relative;
}
.tooltip__widget i:hover + .tooltip__widget__text {
  display: flex;
}

.tooltip__widget.bottom .tooltip__widget__text {
  top: 115%;
}
.tooltip__widget.bottom .tooltip__widget__text::before {
  top: -5px;
  transform: rotate(180deg);
}
.tooltip__widget.top .tooltip__widget__text {
  bottom: 115%;
}
.tooltip__widget.top .tooltip__widget__text::before {
  bottom: -5px;
}
.tooltip__widget.left .tooltip__widget__text {
  transform: translateX(calc(-100% + 14px));
}
.tooltip__widget.left .tooltip__widget__text::before {
  right: 1px;
}
.tooltip__widget.right .tooltip__widget__text::before {
  left: 1px;
}

.tooltip__widget__text {
  width: max-content;
  max-width: 250px;
  position: absolute;
  left: 0;
  display: none;
  font-weight: lighter;
  z-index: 3000;
}
.tooltip__widget__text::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.tooltip__widget__text .tooltip-inner {
  width: 100%;
  max-width: unset;
  white-space: normal;
}

.pointer {
  cursor: pointer;
}

.select_ch {
  white-space: nowrap;
  margin-top: 15px !important;
  font-size: 11px !important;
  padding: 3px 7px;
}

.btn-link {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.notify_date {
  font-size: 0.75em;
}

.table-content {
  position: relative;
  min-height: 150px;
}

.table--loading {
  opacity: 0.5;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.loader {
  position: relative;
  top: 87px;
  color: var(--green);
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
strong {
  font-weight: 600;
}

.btn-icon {
  padding: 4px;
  margin: 0;
  border: none;
  border-radius: 3px;
}
.btn-icon__tonal {
  background: var(--green);
  color: white;
}

input.disabled,
input:disabled,
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.btn.toggle label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.1;
}
.btn.toggle .toggle-on {
  padding-right: 1.5rem;
}

.ui-datepicker {
  background: #FFF;
  padding: 8px 16px;
}
.ui-datepicker .ui-datepicker-header {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  order: -1;
  flex: 1;
  text-align: left;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  order: 10;
  text-align: center;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  order: 15;
  flex: 1;
  text-align: right;
  cursor: pointer;
}
.ui-datepicker table.ui-datepicker-calendar th, .ui-datepicker table.ui-datepicker-calendar td {
  width: 30px;
  height: 30px;
  text-align: center;
}

.datatable--wrapper .table-responsive .dataTables_filter {
  text-align: right;
}
.datatable--wrapper .table-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.datatable--wrapper .table-header .table-buttons {
  margin-bottom: 0.5rem;
}
.datatable--wrapper .table-header .table-buttons .btn {
  margin-bottom: 0.25rem;
}
.datatable--wrapper .table-header .dataTables_filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 5px;
  margin-bottom: 12px;
  color: var(--secondary);
}
.datatable--wrapper .table-header .dataTables_filter label {
  margin: 0;
  font-size: 0;
  color: transparent;
}
.datatable--wrapper .table-header .dataTables_filter input {
  width: 240px;
  margin-left: 0;
}
.datatable--wrapper table.dataTable > thead {
  background: rgb(229, 236, 246);
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #414042;
}
.datatable--wrapper table.dataTable > thead th {
  padding: 11px 0.75rem;
  border-bottom: 0;
}
.datatable--wrapper table.dataTable > thead tr .sorting::before, .datatable--wrapper table.dataTable > thead tr .sorting::after,
.datatable--wrapper table.dataTable > thead tr .sorting_asc::before,
.datatable--wrapper table.dataTable > thead tr .sorting_asc::after,
.datatable--wrapper table.dataTable > thead tr .sorting_desc::before,
.datatable--wrapper table.dataTable > thead tr .sorting_desc::after,
.datatable--wrapper table.dataTable > thead tr .sorting_asc_disabled::before,
.datatable--wrapper table.dataTable > thead tr .sorting_asc_disabled::after,
.datatable--wrapper table.dataTable > thead tr .sorting_desc_disabled::before,
.datatable--wrapper table.dataTable > thead tr .sorting_desc_disabled::after {
  display: none;
}
.datatable--wrapper table.dataTable > thead .sorting .sort {
  --sort-color: #b4b4b4;
}
.datatable--wrapper table.dataTable > thead .sorting .sort::before, .datatable--wrapper table.dataTable > thead .sorting .sort::after {
  content: "";
  position: absolute;
  left: auto;
  right: auto;
  display: inline-block;
  margin-left: 4px;
  border: 4px solid transparent;
}
.datatable--wrapper table.dataTable > thead .sorting .sort::before {
  top: calc(50% - 10px);
  bottom: auto;
  border-bottom: 4px solid var(--sort-color);
}
.datatable--wrapper table.dataTable > thead .sorting .sort::after {
  top: 50%;
  bottom: auto;
  border-top: 4px solid var(--sort-color);
}
.datatable--wrapper table.dataTable > thead .sorting:hover .sort {
  --sort-color: var(--secondary);
}
.datatable--wrapper table.dataTable > thead .sorting_desc .sort::before {
  opacity: 0;
}
.datatable--wrapper table.dataTable > thead .sorting_asc .sort::after {
  opacity: 0;
}
.datatable--wrapper table.dataTable > thead .datatable-column-filter-row input, .datatable--wrapper table.dataTable > thead .datatable-column-filter-row select {
  display: block;
  width: 100%;
  font-weight: 300;
  color: #414042;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.6484375rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.datatable--wrapper table.dataTable > thead .datatable-column-filter-row input:focus, .datatable--wrapper table.dataTable > thead .datatable-column-filter-row select:focus {
  outline: none;
}
.datatable--wrapper table.dataTable > thead .datatable-column-filter-row th {
  border: 0;
  padding-top: 0;
}
.datatable--wrapper table.dataTable > tbody th, .datatable--wrapper table.dataTable > tbody td {
  border-top: 0;
  line-height: 16px;
}
.datatable--wrapper table.dataTable.table-striped > tbody tr {
  background: #FFF;
}
.datatable--wrapper table.dataTable.table-striped > tbody tr:nth-of-type(even) {
  background: #ecf1f8;
}
@media screen and (min-width: 768.02px) {
  .datatable--wrapper {
    flex-direction: row;
  }
}

.table-footer {
  --info-width: 25%;
  --pagination-width: 50%;
  --pagesize-width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.table-footer .dataTables_info, .table-footer__info {
  flex: 0 0 auto;
  width: var(--info-width);
}
.table-footer .dataTables_paginate, .table-footer__pagination {
  order: -1;
  flex: 0 0 auto;
  width: var(--pagination-width);
}
.table-footer .dataTables_paginate .pagination, .table-footer__pagination .pagination {
  margin: 1rem auto;
  justify-content: center;
}
.table-footer .dataTables_length, .table-footer__page-size {
  flex: 0 0 auto;
  width: var(--pagesize-width);
  white-space: nowrap;
}
.table-footer .dataTables_length > label, .table-footer__page-size > label {
  display: block;
  width: 100%;
  font-weight: 300;
  margin: 1rem 0 1rem auto;
}
.table-footer .dataTables_length select, .table-footer__page-size select {
  width: 60px;
}
@media screen and (min-width: 768.02px) {
  .table-footer .dataTables_info, .table-footer__info {
    order: 10;
    padding: 1rem 0;
  }
  .table-footer .dataTables_paginate, .table-footer__pagination {
    order: 20;
  }
  .table-footer .dataTables_length, .table-footer__page-size {
    order: 30;
    text-align: right;
  }
}
@media screen and (max-width: 768px) {
  .table-footer {
    flex-direction: column;
    --info-width: 100%;
    --pagination-width: 100%;
    --pagesize-width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .col-9 > .card .table-footer {
    --info-width: 100%;
    --pagination-width: 100%;
    --pagesize-width: 100%;
    flex-direction: column;
  }
}

.col-main {
  position: relative;
  padding-right: 7.5px;
  padding-left: 7.5px;
  flex: 0 0 auto;
  width: 100%;
}
@media screen and (min-width: 1600.02px) {
  .col-main {
    width: 83.33333333%;
  }
}

/** Navbar */
.main-header .badge {
  padding: 0 2px;
  font-size: 8px;
}

.main-header .dropdown__item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.main-header .dropdown-toggle .right {
  position: relative;
  top: 1px;
  font-size: 13px;
  color: var(--secondary);
}

.main-header .navbar-nav:first-child {
  font-weight: 300;
  font-size: 14px;
  color: #75C8AE;
}

.main-header .navbar-nav:last-child i[class^=fa] {
  color: var(--black-1);
}
.main-header .navbar-nav:last-child i[class^=fa]:hover {
  opacity: 0.8;
}

@media screen and (max-width: 576px) {
  .user-panel .fa-user {
    color: var(--black-1);
    margin-right: 3px;
    line-height: 30px;
  }
  .user-panel .info {
    display: none;
  }
  .user-panel + .dropdown-menu > span {
    color: var(--green);
  }
}
@media screen and (min-width: 576.02px) {
  .user-panel .fa-user {
    display: none;
  }
  .user-panel + .dropdown-menu > span {
    display: none;
  }
}

.nav-item_separator {
  position: relative;
}
.nav-item_separator::before {
  content: "";
  display: block;
  border-top: 1px solid #CCC;
  margin-top: 2px;
  margin-bottom: 5px;
}

/** Sidebar */
.main-sidebar {
  --sidebar-padding: 12px;
  background: #FFF;
  /** Sidebar nav links */
  /** active */
}
.main-sidebar .brand-link {
  padding: 20px var(--sidebar-padding) 25px;
}
.main-sidebar .sidebar {
  padding: 0 var(--sidebar-padding);
}
.main-sidebar .sidebar .nav-link {
  width: calc(250px - var(--sidebar-padding) * 2);
}
.main-sidebar .sidebar .nav.ml-2 .nav-link { /** next level */
  width: calc(250px - var(--sidebar-padding) * 2 - 0.5rem);
}
.main-sidebar .sidebar .nav.ml-2 .nav.ml-2 .nav-link {
  width: calc(250px - var(--sidebar-padding) * 2 - 1rem);
}
.main-sidebar .nav-sidebar .nav-item .nav-icon,
.main-sidebar .nav-sidebar .nav-subitem .nav-icon {
  position: relative;
  top: -1px;
  margin-left: 0;
  margin-right: 0.2rem;
  font-size: 12px;
  text-align: left;
  width: auto;
}
.main-sidebar .nav-sidebar .nav-treeview > .nav-item > .nav-link > .nav-icon {
  width: auto;
}
.main-sidebar .nav-subitem {
  position: relative;
}
.main-sidebar .nav-link {
  padding: 6.5px 1rem;
  padding-left: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: var(--black-1);
}
.main-sidebar .nav-link:not(.active):hover,
.main-sidebar .nav-link:not(.active):focus {
  color: var(--black-1);
  opacity: 0.8;
}
.main-sidebar .nav-pills .nav-link.active,
.main-sidebar .nav-pills .show > .nav-link {
  background-color: transparent;
  color: var(--green);
  font-weight: 700;
}
.main-sidebar .nav-pills .nav-link.active:hover,
.main-sidebar .nav-pills .show > .nav-link:hover {
  color: var(--green);
  opacity: 0.8;
}
.main-sidebar .fa-angle-left {
  color: var(--violet);
  font-size: 12px;
  line-height: 14px;
}
.main-sidebar .nav-sidebar .nav-link > .fa-angle-left,
.main-sidebar .nav-sidebar .nav-link > p > .fa-angle-left {
  right: 16px;
}
.main-sidebar .badge {
  font-weight: 700;
}
.main-sidebar .nav-sidebar .nav-link > .right:nth-child(2),
.main-sidebar .nav-sidebar .nav-link > p > .right:nth-child(2) {
  right: 1.8rem;
}

.content-header .breadcrumb {
  margin-bottom: 15px;
}

.content-header .breadcrumb-item, .content-header .breadcrumb-item a {
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: #A7A9AC;
}

.content-header .breadcrumb-item a {
  text-decoration: underline;
}

.main-footer {
  z-index: 1000;
  position: relative;
}

.title-bar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding-top: 0.25rem;
  margin-bottom: 10px;
}
.title-bar__title {
  margin-right: 15px;
  margin-bottom: 15px;
}
.title-bar__actions {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: auto;
  margin-bottom: 15px;
}

.placeholder {
  --height: 32px;
  --padding: 8px 15px;
  --border-radius: 20px;
  --icons-offset: 35px;
  position: relative;
  padding: var(--padding);
  background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0, rgba(255, 255, 255, 0.4) 105.18%), #ebecf0;
  background-blend-mode: soft-light, normal;
  box-shadow: inset -5px -5px 10px #fafbff, inset 5px 5px 10px #a6abbd;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
}
.placeholder_input {
  padding: 0;
  height: var(--height);
}
.placeholder_input > input {
  width: 100%;
  height: var(--height);
  outline: none;
  border: 0;
  background: transparent;
  padding: var(--padding);
  font-size: 14px;
  border-radius: var(--border-radius);
}
.placeholder_input > input:focus {
  background-color: #FFF;
}
.placeholder_inline {
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.placeholder_icon {
  padding-right: var(--icons-offset);
}
.placeholder_icon.placeholder_input {
  padding: 0;
}
.placeholder_icon.placeholder_input > input {
  padding-right: var(--icons-offset);
}
.placeholder_icon .icon {
  position: absolute;
  right: 15px;
  top: calc(50% - var(--size) / 2);
}

.menu {
  display: inline-block;
  background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #EBECF0;
  background-blend-mode: soft-light, normal;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px #FAFBFF, inset 5px 5px 10px #A6ABBD;
  border-radius: 40px;
  width: auto;
  height: 32px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.22px;
}
.menu .menu-item {
  display: inline-block;
  padding: 2px 20px 0;
  height: 31px;
  color: #5A3171;
}
.menu .active {
  background: #421D57;
  border-radius: 40px;
}
.menu a {
  text-decoration: none;
  color: #5A3171;
  vertical-align: middle;
}
.menu a:hover, .menu a:focus {
  color: #5A3171;
}
.menu .active a {
  color: #EEECF1;
}
@media screen and (max-width: ) {
  .menu {
    font-size: 13px;
  }
  .menu .menu-item {
    padding: 2px 10px 0;
  }
}

.push-messages .dropdown-menu {
  --scrollbar-foreground: var(--green);
  --scrollbar-background: #CCC;
  --scrollbar-size: 5px;
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
.push-messages .dropdown-menu::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
}
.push-messages .dropdown-menu::-webkit-scrollbar-thumb {
  background: var(--scrollbar-foreground);
}
.push-messages .dropdown-menu::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}
.push-messages .dropdown-menu {
  list-style: none;
  max-height: 500px;
  overflow: auto;
}

.push-message__title {
  font-size: 0.875rem;
}
.push-message__body {
  overflow: hidden;
  white-space: normal;
  font-size: 0.75rem;
  line-height: 1.25;
  max-height: 5.625em;
}
.push-message_open .push-message__body {
  overflow: auto;
  max-height: 30em;
}
.push-message[data-overflown] {
  cursor: pointer;
}
.push-message .more {
  text-decoration: underline;
}
.push-message:hover .more {
  text-decoration: none;
}

.nav-link_notify .navbar-badge {
  padding: 1px 2px;
  font-size: 0.6rem;
  font-weight: 700;
}

.notify-list {
  padding-top: 10px;
  padding-left: 0;
}

.dropdown-menu__summary {
  position: sticky;
  bottom: 0;
  display: flex;
}
.dropdown-menu__summary a {
  width: 100%;
  padding: 8px;
  text-align: center;
  background-color: var(--green);
  color: #FFF !important;
}
.dropdown-menu__summary a:hover {
  opacity: 0.95;
}

.custom-checkbox--list input {
  display: none;
}
.custom-checkbox--list_justify .custom-checkbox {
  justify-content: space-between;
}

.custom-checkbox {
  --color-disabled: #CCCECF;
  --color-enabled: var(--green);
  display: flex !important;
  align-items: center;
  margin: 2px 10px 2px 0;
  cursor: pointer;
}
.custom-checkbox__label {
  margin-right: 5px;
  font-weight: normal;
  font-size: 12px;
  color: var(--black-1);
}
.custom-checkbox__state {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 12px;
  border: 1px solid #CCCECF;
  border-radius: 2px;
}
.custom-checkbox__state::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 8px;
  top: 50%;
  left: 1px;
  background-color: var(--color-disabled);
  border-radius: 2px;
  transform: translate(0%, -50%);
  transition: all 0.2s ease;
}
input:checked ~ .custom-checkbox__state::after {
  transform: translate(100%, -50%);
  background-color: var(--color-enabled);
}

.custom-radio--list input {
  display: none;
}
.custom-radio--list_justify .custom-radio {
  justify-content: space-between;
}
.custom-radio--list_inline {
  display: flex;
}

.custom-radio {
  --color-disabled: #CCCCCC;
  --color-enabled: var(--green);
  display: flex !important;
  align-items: center;
  margin: 2px 10px 2px 0;
  cursor: pointer;
}
.custom-radio__label {
  font-weight: normal;
  font-size: 12px;
  color: var(--black-1);
}
.custom-radio__state {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid var(--color-disabled);
  border-radius: 100%;
}
.custom-radio__state::after {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-enabled);
  border: 2px solid #FFF;
  border-radius: 100%;
  box-shadow: 0 0 0 1px var(--color-disabled);
  transition: opacity 0.2s ease;
  opacity: 0;
}
input:checked ~ .custom-radio__state::after {
  opacity: 1;
}

.projects-table__name {
  font-weight: 700;
}

.brand-table tbody td:nth-child(1):not(.dataTables_empty) {
  font-weight: 700;
  font-size: 14px;
}

.categories-table tbody td:nth-child(1):not(.dataTables_empty) {
  font-weight: normal;
  font-size: 14px;
}

.product-table tbody td:nth-child(2) {
  font-weight: 700;
  font-size: 14px;
}

.subproduct-table tbody td:nth-child(3) {
  font-weight: 700;
  font-size: 14px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #30B58E;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  border: 1px solid #ced4da;
}

.urls-table td {
  padding: 12px 5px;
}
.urls-table td:first-child {
  padding-left: 12px;
}
.urls-table td:last-child {
  padding-right: 12px;
}
.urls-table .urls__domain_link {
  color: blue;
  max-width: 240px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.spoiler__button {
  position: relative;
  display: flex;
  align-items: center;
}
.spoiler__button i {
  margin-right: 10px;
}

.spoiler__body {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  display: flex !important;
  overflow: hidden;
}
.spoiler__body.show {
  max-height: 550px;
  transition: max-height 0.25s ease-in;
  overflow: unset;
  overflow-y: scroll;
}

.form-list_radios .form-list-group {
  display: flex;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #414042;
  letter-spacing: 0;
  text-align: left;
}

.form-list_radios .form-list-input {
  display: none;
}

.form-list_radios .form-list-label {
  position: relative;
  margin-bottom: 2px;
  padding-left: 14px;
  cursor: pointer;
}

.form-list_radios .form-list-input ~ .form-list-label::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.form-list_radios .form-list-input:checked ~ .form-list-label::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 2px;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #30B58E;
}

.form-list_radios.form-list_inline {
  display: flex;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
}

.form-list_radios.form-list_xl .form-list-label {
  margin-top: 2px;
  padding-left: 22px;
}

.form-list_radios.form-list_xl .form-list-input ~ .form-list-label::before {
  top: 0px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid;
}

.form-list_radios.form-list_xl .form-list-input:checked ~ .form-list-label::after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
}

.grid-region .form-list_radios .form-list-group {
  padding-top: 2px;
  padding-bottom: 2px;
  align-items: center;
}

.simplebar-content .form-list_radios .form-list-group:hover,
.simplebar-content .form-list_radios .active {
  background-color: #30B68F;
}

.simplebar-content .form-list_radios .form-list-group:hover .form-list-input:checked ~ .form-list-label::after,
.simplebar-content .form-list_radios .active .form-list-input:checked ~ .form-list-label::after {
  background: #414042;
}

.simplebar-content .form-list_radios .form-list-group:hover .js-toggle-list,
.simplebar-content .form-list_radios .active .js-toggle-list {
  filter: grayscale(1) brightness(0.5);
}

.simplebar-content .form-list_radios .form-list-label {
  margin-top: 3px;
}

.simplebar-content .form-list_radios .form-list-group .js-toggle-list {
  margin-top: 3px;
  margin-bottom: 3px;
}

.form-text,
.form-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.2;
  background-color: #fff;
  color: #414042;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-text:focus,
.form-input:focus {
  border-color: #d0ece4;
  outline: 0;
}

.review__comments--wrap {
  display: flex;
}
.review__comments--wrap .review__comments {
  flex: 1;
  padding-right: 5px;
}
.review__comments--wrap .review__comments [data-tone] {
  cursor: pointer;
}
.review__comments--wrap .review__comments [data-tone=pos].topic {
  background: rgb(117, 200, 174);
}
.review__comments--wrap .review__comments [data-tone=neg].topic {
  background: #dc3545;
  color: #ffffff;
}
.review__comments--wrap .review__comments [data-tone=neut].topic {
  background: #6c757d;
  color: #ffffff;
}
.review__comments--wrap .review__topics {
  width: 25%;
  flex: 0 0 auto;
  padding-left: 5px;
}
.review__comments--wrap .review__topics ul {
  list-style: none;
  padding-left: 0;
}
.review__comments--wrap .review__topics li {
  cursor: pointer;
}
.review__comments--wrap .review__topics li::before {
  content: "- ";
}
.review__comments--wrap .review__topics li:not(.active):hover {
  background: rgba(117, 200, 174, 0.5);
}
.review__comments--wrap .review__topics li:not(.active):hover a {
  color: #45ac8c;
}
.review__comments--wrap .review__topics .active {
  background: rgb(117, 200, 174);
  font-weight: bold;
}
.review__comments--wrap .review__topics .active a {
  color: rgb(65, 64, 66);
}

.review {
  --column-1: 250px;
  --column-2: 150px;
  --column-3: 250px;
  --column-4: 250px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: var(--black-1);
}
.review [data-kw-mark] {
  background: yellow;
}
.review label {
  display: block;
  margin-bottom: 4px;
}
.review__product-name {
  width: 100%;
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--black-1);
}
.review__body {
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: max-content var(--column-2) minmax(var(--column-2), var(--column-1)) minmax(var(--column-2), 1fr);
  width: 100%;
  max-width: unset;
  row-gap: 8px;
  column-gap: 10px;
}
@media (max-width: 1449px) {
  .review__body {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
}
.review__comments {
  width: 100%;
}
.review__comment {
  display: inline-block;
}
.review__review-star {
  white-space: nowrap;
}
.review__review-star i {
  color: var(--primary);
}
.review__review-pos {
  width: 100%;
  margin-bottom: 5px;
}
.review__review-neg {
  width: 100%;
  margin-bottom: 5px;
}
.review__review-text {
  width: 100%;
}
.review__comment {
  overflow: auto;
  max-height: 40px;
  margin-bottom: 5px;
  transition: 0.2s linear;
}
.review__more {
  display: none;
}
.review .has-scroll .review__comment {
  position: relative;
  overflow: hidden;
}
.review .has-scroll .review__comment::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  pointer-events: none;
}
.review .has-scroll .review__more {
  display: block;
  order: 99;
}
.review .has-scroll .review__more input {
  display: none;
}
.review .has-scroll .toggle-down {
  display: inline-block;
}
.review .has-scroll .toggle-up {
  display: none;
}
.review .has-scroll.active .review__comment {
  max-height: 1000px;
}
.review .has-scroll.active .review__comment::after {
  opacity: 0;
}
.review .has-scroll.active .toggle-down {
  display: none;
}
.review .has-scroll.active .toggle-up {
  display: inline-block;
}
.review .has-scroll .toggle-up, .review .has-scroll .toggle-down {
  text-decoration: underline;
}

.topics__reaction {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 18px;
  line-height: 1;
}

.markup {
  max-height: 360px;
  padding: 5px;
  overflow-y: scroll;
}

.markup__topics .tone-pos .topics__pos {
  font-weight: bold;
  color: inherit;
}
.markup__topics .tone-neg .topics__neg {
  font-weight: bold;
  color: inherit;
}
.markup__topics .tone-neut .topics__neut {
  font-weight: bold;
  color: inherit;
}

.topics input[id*=topic-name] {
  border: 1px solid transparent;
  background: transparent;
  border-radius: 0.25rem;
}
.topics .topics__edited input[id*=topic-name] {
  border: 1px solid #ced4da;
}
.topics .topics__btn-icon {
  height: 25px;
  width: 25px;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}
.topics .topics__edited .topics__edit-on,
.topics .topics__edit-update,
.topics .topics__edit-cancel {
  display: none;
}
.topics .topics__edited .topics__edit-update,
.topics .topics__edited .topics__edit-cancel {
  display: initial;
}

.report .dash-table {
  --head-bg: rgb(229, 236, 246);
  --even-row-bg: #ecf1f8;
  color: #212529;
  margin-bottom: 1rem;
}
.report .dash-table .dash-spreadsheet-container {
  font-size: 12px;
  line-height: 15px;
}
.report .dash-table .cell-table,
.report .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner table {
  --accent: var(--secondary);
  font-family: var(--font-family-base);
}
.report .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner td,
.report .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner th {
  text-align: left;
  padding: 0.75rem;
}
.report .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner th,
.report .dash-table .dt-table-container__row-0 th {
  background: var(--head-bg);
  color: #414042;
  font-weight: 700;
  padding: 10px 0.75rem;
}
.report .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner tr:nth-child(even), .report .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner tr:nth-child(even) td {
  background-color: var(--even-row-bg);
}
.report .dash-table .dt-table-container__row-0 th > div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.report .dash-table .dt-table-container__row-0 th > div .column-actions {
  margin-left: 4px;
}
.report .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner .column-header-name {
  flex-grow: 0;
}
.report .dash-table .dash-cell {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 0;
  font-stretch: ultra-condensed;
  border: 1px;
  border-style: initial;
  border-color: initial;
}
.report .table-footer__pagination {
  --font-size: 14px;
}
.report .table-footer__pagination .page-item, .report .table-footer__pagination .page-link {
  font-size: var(--font-size);
}
.report .table-footer__pagination .page-link > span {
  display: none;
}
@media screen and (max-width: 1200px) {
  .report .table-footer__pagination .page-item_arrow .page-link {
    font-size: 0;
  }
  .report .table-footer__pagination .page-item_arrow .page-link > span {
    display: inline;
    font-size: var(--font-size);
  }
}
@media screen and (max-width: 768px) {
  .report .table-footer__pagination {
    --font-size: 12px;
  }
  .report .table-footer__pagination .page-link {
    padding: 0.5rem 0.625rem;
  }
}
.report .table-footer__page-size {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.report .table-footer__page-size .dash-dropdown {
  text-align: left;
  width: 60px;
  margin-left: 10px;
}
@media screen and (max-width: 1600px) {
  .report .table-footer {
    --info-width: 50%;
    --pagination-width: 100%;
    --pagesize-width: 50%;
    flex-wrap: wrap;
  }
  .report .table-footer__pagination {
    order: -1;
  }
  .report .table-footer__page-size {
    margin-left: auto;
  }
}

.report .Select.is-focused:not(.is-open) > .Select-control {
  border-color: #d9d9d9 #ccc #b3b3b3;
  box-shadow: none;
}
.report .Select--single .Select-value {
  font-size: 12px;
}
.report .Select--multi .Select-value-icon {
  border-right: 0;
  font-size: 16px;
  line-height: 10px;
  padding-right: 1.5px;
}
.report .Select--multi .Select-value-icon:hover {
  opacity: 0.85;
}
.report .Select--multi .Select-placeholder {
  font-size: 12px;
}
.report .Select--multi .Select-value-label {
  padding-left: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}

@media screen and (max-width: 768px) {
  .report__filter {
    order: -1;
  }
}
.report .card {
  background-color: #fff;
}
.report .form-group-label {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #414042;
}
.report .DateRangePickerInput__withBorder {
  border-radius: 4px;
}
.report .DateInput {
  width: 85px;
}
.report .DateInput_input {
  padding: 3px 10px;
  font-size: 14px;
}
.report .card_header {
  z-index: 500;
  position: relative;
}
.report .card_header .card-body {
  display: flex;
}
.report .card_header .form-group {
  margin: 0 1rem 0 0;
}
.report .card_header .Select-control {
  min-width: 200px;
}
.report .card_header .period-filter-select {
  display: inline-block;
  padding: 8px;
}
@media screen and (max-width: 768px) {
  .report .card_header .form-group {
    margin: 0 0 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .report .card_header .filter-group {
    display: flex;
    flex-direction: column;
  }
  .report .card_header .filter-group .form-group {
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 768px) {
  .report .card_header .card-body {
    flex-direction: column;
  }
  .report .card_header .filter-group {
    margin: 0;
  }
  .report .card_header .filter-group .form-group {
    margin: 0 0 0.5rem;
  }
}
.report .card_filter {
  --slider-color: var(--primary);
}
.report .card_filter .rc-slider-track {
  background-color: var(--slider-color);
}
.report .card_filter .rc-slider-dot-active, .report .card_filter .rc-slider-handle {
  border-color: var(--slider-color);
}
.report .card_filter .rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px var(--slider-color);
}
.report .card_filter .dash-dropdown i, .report .card_filter .dash-dropdown .fa {
  color: var(--primary);
}
.report .filter-group {
  display: flex;
  margin-right: 15px;
}
@media (min-width: 1400px) {
  .report .filter-group {
    margin-right: 50px;
  }
}
.report .dash-rating {
  display: flex;
  align-items: center;
  width: 360px;
  max-width: 100%;
  margin-bottom: 0.5rem;
}
.report .dash-rating .dash-dropdown {
  flex: 1;
  margin-left: 8px;
}
.report .dash-graph--wrap {
  max-width: 100%;
  overflow: auto;
  margin-bottom: 1rem;
}
.report .df-empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.report .show-filters-mobile {
  display: none;
}
.report .dash-graph {
  min-width: 500px;
}
@media screen and (max-width: 768px) {
  .report .show-filters-mobile {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
  .report .show-filters-mobile:not(.open)::after {
    content: "";
    position: relative;
    height: 0;
    width: 0;
    top: 2px;
    left: 8px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
  }
  .report .filters-mobile:not(.open) {
    display: none;
  }
}

.report .rep-regions-page .dash-table .dt-table-container__row {
  --scrollbar-foreground: #007eff;
  --scrollbar-background: var(--black-1);
  --scrollbar-size: 5px;
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
.report .rep-regions-page .dash-table .dt-table-container__row::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
}
.report .rep-regions-page .dash-table .dt-table-container__row::-webkit-scrollbar-thumb {
  background: var(--scrollbar-foreground);
}
.report .rep-regions-page .dash-table .dt-table-container__row::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}
.report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner th, .report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner td,
.report .rep-regions-page .dash-table .dt-table-container__row th,
.report .rep-regions-page .dash-table .dt-table-container__row td {
  padding-top: 2px;
  padding-bottom: 2px;
}
.report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner th > div,
.report .rep-regions-page .dash-table .dt-table-container__row th > div {
  justify-content: center;
}
.report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner tr,
.report .rep-regions-page .dash-table .dt-table-container__row tr {
  height: 24px;
  min-height: 24px;
}
.report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner td,
.report .rep-regions-page .dash-table .dt-table-container__row td {
  position: relative;
  font-weight: 500;
}
.report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner td::before, .report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner td::after,
.report .rep-regions-page .dash-table .dt-table-container__row td::before,
.report .rep-regions-page .dash-table .dt-table-container__row td::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  display: block;
  background: #FFF;
}
.report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner td::after,
.report .rep-regions-page .dash-table .dt-table-container__row td::after {
  top: auto;
  bottom: 0;
}
.report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner tr:nth-child(even) td::before, .report .rep-regions-page .dash-table .dash-table-container .dash-spreadsheet-container .dash-spreadsheet-inner tr:nth-child(even) td::after,
.report .rep-regions-page .dash-table .dt-table-container__row tr:nth-child(even) td::before,
.report .rep-regions-page .dash-table .dt-table-container__row tr:nth-child(even) td::after {
  background: var(--even-row-bg);
}

#tooltip {
  z-index: 9999;
  display: inline-block;
  padding: 5px 10px;
  background: #ffffff;
  color: #000;
  font-weight: bold;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #AAA;
}

[data-popper-arrow],
[data-popper-arrow]::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^=top] > [data-popper-arrow] {
  bottom: -4px;
}

#tooltip[data-popper-placement^=bottom] > [data-popper-arrow] {
  top: -4px;
}

#tooltip[data-popper-placement^=left] > [data-popper-arrow] {
  right: -4px;
}

#tooltip[data-popper-placement^=right] > [data-popper-arrow] {
  left: -4px;
}

.selected-bound {
  position: absolute;
  pointer-events: none;
}

.markup__header {
  margin-bottom: 8px;
}
.markup__text {
  color: #643045;
}