.form-text,
.form-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.2;
  background-color: #fff;
  color: #414042;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-text:focus,
.form-input:focus {
  border-color: #d0ece4;
  outline: 0;
}
